/*******
 * Copyright 2017-2021 - EmPowerYu, inc.
 *
 */

import { URLS } from '../shared/urls';
import LocalStorageAPI from './LStorage';
import {setUserOptionDefaults, setInactivityTimeout} from '../shared/constants';
import axios from 'axios';

let epyCaregiverId = undefined;
let epyUserType = undefined;
let epyOrganizationName = undefined;
let epyloginListeners = {};

function EpyCaregiverServices() {
    function validateUserCredentials(username, password, handler) {
        let url = URLS.validateusercredentials;

        axios.post(url, { username: username, password: password})
            .then(function (response) {
                let jsonData = response.data;
                let userInfo;

                try {
                    if (typeof jsonData === "string") {
                        userInfo = JSON.parse(jsonData);
                    } else {
                        userInfo = jsonData;
                    }

                    if (userInfo.success) {
                        epyCaregiverId = userInfo.caregiverId;
                        epyUserType = userInfo.userType;
                        
                        // white list change
                        // epyOrganizationName = userInfo.organizationName;
                        epyOrganizationName = "AI Caring";
                        let inactivityTimeout = userInfo.inactivity_timeout;


                        delete userInfo.timestamp;
                        delete userInfo.success;
                        LocalStorageAPI.setCaregiverId(username, epyOrganizationName, epyCaregiverId, epyUserType, inactivityTimeout);

                        setUserOptionDefaults( epyUserType);
                        dispatchLoginChangeNotification();
                        setInactivityTimeout(inactivityTimeout);
                        (handler)(userInfo, true);
                    } else {
                        console.log("validateUserCredentials: error validating credentials. username = "+username);
                        (handler)(undefined, false);
                    }
                }
                catch (e) {
                    console.log("validateUserCredentials: error parsing credentials validatio JSON. Err = " + e.message);
                    (handler)(undefined, false);
                }
            })
            .catch(function (error) {
                console.log('validateUserCredentials: ' + error);
                (handler)(undefined, false);
            });

        return true;
    }

    function logout() {
        let url = URLS.logoutUser;
        let caregiverId = epyCaregiverId;
        epyCaregiverId = undefined;
        epyUserType = undefined;

        LocalStorageAPI.resetLocalStorage();

        if (caregiverId) {
            axios.post(url, {caregiverId: caregiverId})
                .then(function () {
                    console.log('logout: successfully logged.');
                })
                .catch(function (error) {
                    console.log('logout: '+error);
            });
        }

        dispatchLoginChangeNotification();
        return true;
    }

    function forceLogout() {
        LocalStorageAPI.resetLocalStorage();
        dispatchLoginChangeNotification();
    }

    function getLoginInfo(id, handler) {
        if (id === undefined && epyCaregiverId === undefined) {
            const caregiverId = LocalStorageAPI.getCaregiverId();
            if (caregiverId !== undefined  || caregiverId === null) {
                epyCaregiverId = caregiverId;
                epyUserType = LocalStorageAPI.getUserType();
                epyOrganizationName = LocalStorageAPI.getOrganizationName();
            }
        }
        if (id && handler) {
            epyloginListeners[id] = handler;
        }

        return { caregiverId: epyCaregiverId, userType: epyUserType, organizationName: epyOrganizationName };
    }

    function setCurrentPatientId(id) {
        LocalStorageAPI.setPatientList(id)
    }

    function getCurrentPatientId() {
        return LocalStorageAPI.getPatientId();
    }

    function getUsername() {
        return LocalStorageAPI.getUsername();
    }

    function getOrganizationName() {
        return LocalStorageAPI.getOrganizationName();
    }

    function dispatchLoginChangeNotification() {
        for (let entry in epyloginListeners) {
            (epyloginListeners[entry]) ({ caregiverId: epyCaregiverId, userType: epyUserType, organizationName: epyOrganizationName });
        }
    }

    return {
        validateUserCredentials: validateUserCredentials,
        getLoginInfo: getLoginInfo,
        setCurrentPatientId,
        getCurrentPatientId,
        getUsername,
        getOrganizationName,
        logout,
        forceLogout
    }
}

let _epyCaregiverServices = EpyCaregiverServices();

let CaregiverServices = {
    validateUserCredentials: function(username, password, handler) {
        return _epyCaregiverServices.validateUserCredentials(username, password, handler);
    },
    getLoginInfo: function(id, handler) {
        return _epyCaregiverServices.getLoginInfo(id, handler);
    },
    getCurrentPatientId: function() {
        return _epyCaregiverServices.getCurrentPatientId();
    },
    setCurrentPatientId: function(id) {
        return _epyCaregiverServices.setCurrentPatientId(id);
    },
    getUsername:function() {
        return _epyCaregiverServices.getUsername();
    },
    getOrganizationName:function() {
        return _epyCaregiverServices.getOrganizationName();
    },
    logout: function() {
        return _epyCaregiverServices.logout();
    },
    forceLogout: () => { _epyCaregiverServices.forceLogout() }
};

export default CaregiverServices;
