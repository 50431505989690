/*******
 * Copyright 2017-2021 - EmPowerYu, inc.
 *
 */

import axios from 'axios';
import moment from 'moment';

import {URLS} from '../shared/urls';
import { DEFAULT_OPTION_VALUES_BY_MLEVEL, ADVANCED_OPTION_VALIDATION_DEFAULTS } from '../shared/constants';
import Utils from '../shared/Utilities';
import LocalStorageAPI from "./LStorage.js";

const PATIENTSTATE_LOWRISK = 1;
const PATIENTCONNECTIVITY_CONNECTED = "1";

let _advancedOptionValidationDefaults = ADVANCED_OPTION_VALIDATION_DEFAULTS;

let epyPatientList = [];
let epyPatientObj = {};
let epyAdvancedOptions = [];
let epyAOValidationInfo = {};
let epyPLTimeStamp = -1;        // patientList timestamp
let epyDataFactory = undefined;
let showTimeoutPopup = false;

const dataFromLocalStorage = (caregiverId, patientId) => {
    caregiverId = LocalStorageAPI.getCaregiverId();
    if (! patientId) {
        patientId = LocalStorageAPI.getPatientId();
    }
    
    return {caregiverId, patientId }
};

let _epyDataFactory = function () {
    function keepAlive() {
        console.log(`${new moment().format('M/D/YYYY hh:mm:ssA')} keep alive`);
        let url = URLS.keepAlive;
        let caregiverId = LocalStorageAPI.getCaregiverId();

        axios.post(url, {caregiverId: caregiverId})
            .then(function (response) {
                let jsonData = response.data;
                let statusCode = response.status;

                if (jsonData.success) {
                    if (jsonData.caregiverId) {
                        LocalStorageAPI.updateCaregiverId(jsonData.caregiverId);
                    }
                } else {
                    console.log(`keepAlive: error setting keepalive.  status is ${statusCode}`);
                }
            })
            .catch(function (error) {
                let responseCode = error.response.status;
                if (responseCode === 401) {
                    console.log('keepAlive:  user logged out by server');
                } else {
                    console.log("keepAlive: " + error);
                }
            });
    }

    function getPatientList(caregiverId, caller) {
        let url = URLS.getPatientList;
        caregiverId = LocalStorageAPI.getCaregiverId();

        axios.post(url, {caregiverId: caregiverId})
            .then(function (response) {
                let data = {};
                let jsonData = response.data;
                let statusCode = response.status;

                try {
                    if (typeof jsonData === "string") {
                        data = JSON.parse(jsonData);
                    } else {
                        data = jsonData;
                    }

                    //console.log("patientDataService: JSON data - " + JSON.stringify(jsonData));
                    if (data.success) {
                        if (data.caregiverId) {
                            LocalStorageAPI.updateCaregiverId(data.caregiverId);
                        }
                        if (data.timestamp > epyPLTimeStamp) {
                            epyPLTimeStamp = data.timestamp;
                            epyPatientList.length = 0;
                            data.patients.forEach(patient => {
                                patient.monitoringLevel = ( patient.monitoringLevel === null) ?
                                     1 : patient.monitoringLevel;
                                if (typeof patient.monitoringLevel === "string") {
                                    patient.monitoringLevel = parseInt(patient.monitoringLevel, 10);
                                }
                                epyPatientList.push(patient);
                                epyPatientObj[patient.patientID] = patient;
                            });

                           LocalStorageAPI.setPatientList(epyPatientList, epyPatientObj);
                           (caller)(epyPatientList, statusCode);
                        } else {
                            console.log("patientDataService: data has older timestamp. " + data.timestamp);
                            (caller)(epyPatientList, -1);
                        }
                    } else {
                        console.log("patientDataService: error reading patient list.");
                        (caller)(undefined, -2);
                    }
                }
                catch (e) {
                    console.log("patientDataService: error parsing patient list JSON. Err = " + e.message);
                    (caller)(undefined, -3);
                }
            })
            .catch(function (error) {
                let responseCode = error.response.status;
                if (responseCode === 401) {
                    console.log('user logged out by server');
                } else {
                    console.log("getPatientList: " + error);
                }
                (caller)(undefined, responseCode);
            });

        return epyPatientList;
    }

    function getFilteredPatientList(filter, attentionChecked) {
        let filteredList = [];
        let patient;
        if (filter === 0) {
            filteredList = epyPatientList;
        } else {
            for (let i = 0, len = epyPatientList.length; i < len; i++) {
                patient = epyPatientList[i];
                if (patient.monitoringLevel === filter) {
                    filteredList.push(patient);
                }
            }
        }

        if (attentionChecked) {
            let attentionList = [];
            for (let j = 0, len = filteredList.length; j < len; j++) {
                patient = filteredList[j];
                if (patient.patientState !== PATIENTSTATE_LOWRISK) {
                    attentionList.push(patient);
                }
            }
            filteredList = attentionList;
        }

        return filteredList;
    }

    function getPatientSummary() {
        let summaryData = {
            level1: 0,
            level2: 0,
            level3: 0,
            notConnected: 0,
            needAttention: 0
        };

        if (epyPatientList.length > 0) {
            for (let j = 0, len = epyPatientList.length; j < len; j++) {
                let patient = epyPatientList[j];
                switch (patient.monitoringLevel) {
                    case 1:
                        summaryData.level1++;
                        break;

                    case 2:
                        summaryData.level2++;
                        break;

                    case 3:
                        summaryData.level3++;
                        break;

                    default:
                        console.log('getPatientSummary: patient with no monitoring level');
                        break;

                }

                if (patient.patientState !== PATIENTSTATE_LOWRISK) {
                    summaryData.needAttention++;
                }

                if (patient.connectedStatus !== PATIENTCONNECTIVITY_CONNECTED) {
                    //console.log('typeof connectedStatus: "' + typeof patient.connectedStatus + '"; value = ' + patient.connectedStatus);
                    summaryData.notConnected++;
                }
            }
        } else {
            console.log('patientDataService.getPatientSummary: No patient data available.')
        }

        return summaryData;
    }

    function getPatient(caregiverId, patientId) {
        let rv = _getPatient(caregiverId, patientId);
        if (!rv.patientData) {
            console.log("getPatient: " + rv.msg);
        }

        return rv.patientData;
    }

    function getPatientAsync(caregiverId, patientId, callback) {
        let rv = _getPatient(caregiverId, patientId);
        if (rv.patientData) {
            (callback)(rv.patientData, 200);
        } else {
            getPatientList(caregiverId, function (patientList, success) {
                if (success === 200) {
                    let pData = getPatient(caregiverId, patientId);
                    (callback)(pData, success);
                } else {
                    (callback)(undefined, success);
                }
            });
        }

        return true;
    }

    function _getPatient(caregiverId, patientId) {
        let pData = dataFromLocalStorage(caregiverId, patientId, undefined);
        patientId = pData.patientId;
        caregiverId = pData.caregiverId;

        let patientData = undefined;
        let msg = "";

        // const params = false; //checkLocalStorage(caregiverId);
        // if (params.success) {
        //     caregiverId = params.caregiverId;
        // }

        if (epyPatientList.length > 0) {
            if (epyPatientObj[patientId]) {
                patientData = Object.assign({}, epyPatientObj[patientId]);
            } else {
                msg = 'patientDataService.getPatient: unknown patient was passed ' +
                    '(cg:' + caregiverId + ', patientId:' + patientId + ').';
            }
        } else {
            msg = 'patientDataService.getPatient: No patient data available.';
        }

        return {patientData: patientData, msg: msg};
    }

    function getPatientOptions(caregiverId, patientId, callback) {
        let pData = dataFromLocalStorage(caregiverId, patientId, undefined);
        patientId = pData.patientId;
        caregiverId = pData.caregiverId;
        
        let url = URLS.getpatientmonitoringoptions;
        let patientOptions = {};

        axios.post(url, {caregiverId: caregiverId, patientId: patientId})
            .then(function (response) {
                let jsonData = response.data;
                let statusCode = response.status;

                try {
                    if (typeof jsonData === "string") {
                        patientOptions = JSON.parse(jsonData);
                    } else {
                        patientOptions = jsonData;
                    }

                    if (patientOptions.success) {
                        if (patientOptions.caregiverId) {
                            LocalStorageAPI.updateCaregiverId(patientOptions.caregiverId);
                        }
                        let processedOptions = _processPOptions(patientOptions);
                        callback.handlePatientOptions(processedOptions, statusCode);
                    } else {
                        console.log("getPatientOptions: error getting patient options.");
                        callback.handlePatientOptions(undefined, -1);
                    }
                }
                catch (e) {
                    console.log("getPatientOptions: error parsing patient options JSON. Err = " + e.message);
                    callback.handlePatientOptions(undefined, -2);
                }
            })
            .catch(function (error) {
                console.log("getPatientOptions: " + error);
                callback.handlePatientOptions(undefined, error.response.status);
            });

        return patientOptions;
    }

    function _processPOptions(patientOptions) {
        let updPatientOptions = patientOptions;
        let advOptions = updPatientOptions.advancedOptions;
        let advOptionDefaults = updPatientOptions.advOptDefaults;
        delete updPatientOptions.advancedOptions;
        delete updPatientOptions.advOptDefaults;

        let aoObj = {};
        let aoValidate = Object.assign({}, _advancedOptionValidationDefaults);

        if (advOptions) {
            // fix blood pressure advanced options
            // TODO: a bigger fix is needed because BP is really 2 ranges instead of one.
            for (let i = 0, len = advOptions.length; i < len; i++) {
                let aoOption = advOptions[i];
                if (aoOption) {
                    if (aoOption.advOption === "BP" && aoOption.value1 < aoOption.value2) {
                        let tmp = aoOption.value1;
                        aoOption.value1 = aoOption.value2;
                        aoOption.value2 = tmp;
                    }
                    aoObj[aoOption.advOption] = aoOption;
                } else {
                    console.log("getAdvancedOptions._processOptions: missing advanced options");
                }
            }

            // combine the defaults with the validation info
            if (advOptionDefaults) {
                for (let defaultAttrib in advOptionDefaults) {
                    aoValidate[defaultAttrib].value1 = advOptionDefaults[defaultAttrib].value1;
                    aoValidate[defaultAttrib].value2 = advOptionDefaults[defaultAttrib].value2;
                }
            } else {
                console.log('getAdvancedOptions._processOptions: missing advanced option defaults')
            }

            // if (!aoObj.glucose) {
            //     console.log("getAdvancedOptions: didn't get glucose, Using fake data.");
            //     aoObj.glucose = {
            //         "advOption": "glucose",
            //         "value1": 70,
            //         "value2": 99,
            //         "calibrationComplete": true,
            //         "useDefault": false
            //     };
            // }
        }

        epyAdvancedOptions = aoObj;
        epyAOValidationInfo = aoValidate;
        return {patientOptions: updPatientOptions, advancedOptions: aoObj, aoValidationInfo: aoValidate};
    }

    function getDefaultOptions(level) {
        let aoOptions = {};
        let aoValidate = {};
        const defOptionValues = DEFAULT_OPTION_VALUES_BY_MLEVEL[level]
        let mlevelOptionDefaults = Object.assign({}, defOptionValues);

        if (epyAdvancedOptions) {
            aoOptions = epyAdvancedOptions;
        }

        if (epyAOValidationInfo) {
            aoValidate = epyAOValidationInfo;
        }
        return { mlevelOptionDefaults: mlevelOptionDefaults, advOptions: aoOptions, aoValidationInfo: aoValidate };
    }

    function setPatientOptions(caregiverId, patientId, patientOptions, callback) {
        let pData = dataFromLocalStorage(caregiverId, patientId);
        patientId = pData.patientId;
        caregiverId = pData.caregiverId;

        let url = URLS.setpatientmonitoringoptions;
        let options = JSON.stringify(patientOptions);
        // console.log('setPatientOptions:  patient options: ' + options);

        axios.post(url, {caregiverId: caregiverId, patientId: patientId, options: options})
            .then(function (response) {
                let status = response.data;
                let statusCode = response.status;

                try {
                    if (status.success) {
                        if (status.caregiverId) {
                            LocalStorageAPI.updateCaregiverId(status.caregiverId);
                        }
                        callback.handleUpdateOptions(statusCode);
                    } else {
                        console.log("setPatientOptions: error sending options JSON.");
                        callback.handleUpdateOptions(-1);
                    }
                }
                catch (e) {
                    console.log("setPatientOptions: error parsing options JSON. Err = " + e.message);
                    callback.handleUpdateOptions(-2);
                }
            })
            .catch(function (error) {
                let statusCode = error.response.status;
                console.log("setPatientOptions: " + error);
                callback.handleUpdateOptions(statusCode);
            });

        return true;
    }

    function getPatientDetails(caregiverId, patientId, callback) {
        let pData = dataFromLocalStorage(caregiverId, patientId);
        patientId = pData.patientId;
        caregiverId = pData.caregiverId;

        let url = URLS.getpatientdetails;
        let patientDetails = {};

        axios.post(url, {caregiverId: caregiverId, patientId: patientId})
            .then(function (response) {
                let jsonData = response.data;
                let statusCode = response.status;
                let pDetails;
                //console.log("getPatientDetails: JSON data - " + JSON.stringify(jsonData));

                try {
                    if (typeof jsonData === "string") {
                        patientDetails = JSON.parse(jsonData);
                    } else {
                        patientDetails = jsonData;
                    }

                    if (patientDetails.success) {
                        if (patientDetails.caregiverId) {
                            LocalStorageAPI.updateCaregiverId(patientDetails.caregiverId);
                        }
                        pDetails = patientDetails.details;
                        LocalStorageAPI.removePatientVUrl();
                        if (pDetails.vUrl) {
                            LocalStorageAPI.setPatientVUrl(pDetails.vUrl);
                        }
                        let processedOptions = _processPDetails(pDetails);
                        (callback)(processedOptions, statusCode);
                    } else {
                        console.log("getPatientDetails: error getting patient detail.");
                        (callback)(undefined, -1);
                    }
                }
                catch (e) {
                    console.log("getPatientDetails: error parsing patient detail JSON. Err = " + e.message);
                    (callback)(undefined, -2);
                }
            })
            .catch(function (error) {
                let statusCode = error.response.status;
                console.log("getPatientDetails: " + error);
                (callback)(undefined, statusCode);
            });

        return patientDetails;
    }

    function _processPDetails(pDetails) {
        let details = Object.assign({}, pDetails);

        let timeIds = [
            "lastEventTime", "bedTime", "wakeUp", "firstMeal",
            "mostRecentMeal", "firstActivity", "mostRecentActivity"];

        for (let i = 0, len = timeIds.length; i < len; i++) {
            let id = timeIds[i];
            //if (pDetails[id] === -1) continue;

            details[id] = Utils.convertISO8601wTZToTimeData(pDetails[id]);
        }

        for (let i = 0, len = pDetails.unusualEvents.length; i < len; i++) {
            details.unusualEvents[i].time = Utils.convertISO8601wTZToTimeData(details.unusualEvents[i].time);
        }

        return details;
    }

    function resetPatientInfo() {
        console.log("resetPatientInfo: Resetting patient data in system.");
        epyPatientList = [];
        epyPatientObj = {};
        epyPLTimeStamp = -1;
        epyAdvancedOptions = [];
        epyAOValidationInfo = {};
        LocalStorageAPI.resetLocalStorage();
    }

    function getHMapData(patientId, startDate, daysOfData, handler, type) {
        let pData = dataFromLocalStorage(undefined, patientId, undefined);
        patientId = pData.patientId;
        let caregiverId = pData.caregiverId;
        let responseCode = 0;

        let url;
        // console.log(startDate);
        switch (type) {
            default:
            case 'sleeping':
                url = URLS.getSleepHMapData;
                break;

            case 'meals':
                url = URLS.getMealsHMapData;
                break;

            case 'activity':
                url = URLS.getActivityHMapData;
                break;

        }
        axios.post(url, {caregiverId: caregiverId, patientId: patientId, startDate: startDate, daysOfData: daysOfData})
            .then(function (response) {
                let data = {};
                let jsonData = response.data;
                responseCode = response.status;

                try {
                    if (typeof jsonData === "string") {
                        data = JSON.parse(jsonData);
                    } else {
                        data = jsonData;
                    }

                    //console.log("getHMapData: JSON data - " + JSON.stringify(jsonData));

                    if (data.success) {
                        if (data.caregiverId) {
                            LocalStorageAPI.updateCaregiverId(data.caregiverId);
                        }
                            //console.log("getHMapData: (heatmap data) - " + JSON.stringify(data.heatmaps));
                            data = data.heatmaps;
                            (handler)(data, responseCode);

                    } else {
                        console.log("getHMapData: error reading heatmap data.  map type = " + type);
                        (handler)(undefined, -1);
                    }
                }
                catch (e) {
                    console.log("getHMapData: error parsing heatmap JSON. Map Type = " + type + "; Err = " + e.message);
                    (handler)(undefined, -2);
                }
            })
            .catch(function (error) {
                responseCode = error.response.status;
                console.log('getHMapData: ' + error);
                (handler)(undefined, responseCode);
            });

        return epyPatientList;
    }

    function getLegendData(caregiverId, patientId, hmType, handler) {
        let url = URLS.getLegend;
        let legendData = {};

        let pData = dataFromLocalStorage(caregiverId);
        caregiverId = pData.caregiverId;

        axios.post(url, {caregiverId: caregiverId, patientId: patientId, vType: hmType })
            .then(function (response) {
                let jsonData = response.data;
                let responseCode = response.status;
                let legendDetails;

                try {
                    if (typeof jsonData === "string") {
                        legendDetails = JSON.parse(jsonData);
                    } else {
                        legendDetails = jsonData;
                    }

                    if (legendDetails.success) {
                        if (legendDetails.caregiverId) {
                            LocalStorageAPI.updateCaregiverId(legendDetails.caregiverId);
                        }
                        legendDetails.rxDevices = legendDetails.rxdevices;
                        (handler)(legendDetails, responseCode);
                    } else {
                        console.log("getLegendData: error getting legend data.");
                        (handler)(undefined, -1);
                    }
                }
                catch (e) {
                    console.log("getLegendData: Error parsing legend JSON. Err = " + e.message);
                    (handler)(undefined, -2);
                }
            })
            .catch(function (error) {
                console.log("getLegendData: " + error);
                (handler)(undefined, error.response.status);
            });

        return legendData;
    }

    function getUnusualEvents(caregiverId, patientId, startDate, daysOfData, handler) {
        let url = URLS.getUnusualEvents;
        let pData = dataFromLocalStorage(caregiverId, patientId, undefined);
        patientId = pData.patientId;
        caregiverId = pData.caregiverId;

        axios.post(url, {caregiverId: caregiverId,
                patientId: patientId,
                startDate: startDate,
                daysOfData: daysOfData})
            .then(function (response) {
                let jsonData = response.data;
                let responseCode = response.status;
                let uEventsData;

                try {
                    if (typeof jsonData === "string") {
                        uEventsData = JSON.parse(jsonData);
                    } else {
                        uEventsData = jsonData;
                    }

                    if (uEventsData.success) {
                        if (uEventsData.caregiverId) {
                            LocalStorageAPI.updateCaregiverId(uEventsData.caregiverId);
                        }
                        (handler)(uEventsData, responseCode);
                    } else {
                        console.log("getUnusualEvents: error getting unusual events data.");
                        (handler)(undefined, -1);
                    }
                }
                catch (e) {
                    console.log("getUnusualEvents: Error parsing unusual events data JSON. Err = " + e.message);
                    (handler)(undefined, -2);
                }
            })
            .catch(function (error) {
                console.log("getUnusualEvents: " + error);
                (handler)(undefined, error.response.status);
            });

        return true;
    }

    function getRxDeviceData(caregiverId, patientId, startDate, daysOfData, handler) {
        let url = URLS.getRxDeviceData;
        let date = new Date(startDate);
        date.setHours(23,59,59);
        startDate = date.valueOf();

        let pData = dataFromLocalStorage(caregiverId, patientId, undefined);
        patientId = pData.patientId;
        caregiverId = pData.caregiverId;

        axios.post(url, {caregiverId: caregiverId,
                patientId: patientId,
                startDate: startDate,
                daysOfData: daysOfData})
            .then(function (response) {
                let jsonData = response.data;
                let responseCode = response.status;
                let rxData;

                try {
                    if (typeof jsonData === "string") {
                        rxData = JSON.parse(jsonData);
                    } else {
                        rxData = jsonData;
                    }

                    console.log("getRxDeviceData: (jsondata) - " + jsonData);
                    if (rxData.success) {
                        if (rxData.caregiverId) {
                            LocalStorageAPI.updateCaregiverId(rxData.caregiverId);
                        }
                        (handler)(rxData, responseCode);
                    } else {
                        console.log("getRxDeviceData: error getting medical device data.");
                        (handler)(undefined, -1);
                    }
                }
                catch (e) {
                    console.log("getRxDeviceData: Error parsing medical data JSON. Err = " + e.message);
                    (handler)(undefined, -2);
                }
            })
            .catch(function (error) {
                console.log("getUnusualEvents: " + error);
                (handler)(undefined, error.response.status);
            });

        return true;
    }

    function getDeviceEvents(patientId, bIsBasicEvents, deviceId, startDate, daysOfData, handler) {
        let url = URLS.getDeviceEventData;
        let pData = dataFromLocalStorage(undefined, patientId, undefined);
        patientId = pData.patientId;
        let caregiverId = pData.caregiverId;

        axios.post(url, {caregiverId: caregiverId,
                patientId: patientId,
                isBasicEvents: bIsBasicEvents,
                deviceId: deviceId,
                startDate: startDate,
                daysOfData: daysOfData})
            .then(function (response) {
                let jsonData = response.data;
                let responseCode = response.status;
                let devEvents;

                try {
                    if (typeof jsonData === "string") {
                        devEvents = JSON.parse(jsonData);
                    } else {
                        devEvents = jsonData;
                    }

                    if (devEvents.success) {
                        if (devEvents.caregiverId) {
                            LocalStorageAPI.updateCaregiverId(devEvents.caregiverId);
                        }
                        (handler)(devEvents.events, bIsBasicEvents, responseCode);
                    } else {
                        console.log("getDeviceEvents: error getting device events data.");
                        (handler)(undefined, undefined, -1);
                        // (handler)(undefined, undefined, true, -1);
                    }
                }
                catch (e) {
                    console.log("getDeviceEvents: Error parsing unusual device data JSON. Err = " + e.message);
                    (handler)(undefined, undefined, -2);
                    // (handler)(undefined, undefined, true, -2);
                }
            })
            .catch(function (error) {
                console.log("getDeviceEvents: " + error);
                (handler)(undefined, undefined, error.response.status);
                // (handler)(undefined, undefined, true, error.response.status);
            });

        return true;
    }

    function getDeviceCategories(patientId, handler) {
        let url = URLS.getDeviceCategories;
        console.log("getDeviceCategories: '"+url+"'");
        let pData = dataFromLocalStorage(undefined, patientId, undefined);
        patientId = pData.patientId;
        let caregiverId = pData.caregiverId;

        axios.post(url, {caregiverId: caregiverId, patientId: patientId})
            .then(function (response) {
                let jsonData = response.data;
                let responseCode = response.status;
                let devCategories;

                try {
                    if (typeof jsonData === "string") {
                        devCategories = JSON.parse(jsonData);
                    } else {
                        devCategories = jsonData;
                    }

                    let basicDevs = {};
                    for (let i=0, len=devCategories.basicDevices.length; i < len; i++) {
                        let dev = devCategories.basicDevices[i];
                        basicDevs[dev.deviceId] = dev.deviceName;
                    }

                    let detailedDevs = [];
                    for (let i=0, len=devCategories.detailedDevices.length; i < len; i++) {
                        let dev = devCategories.detailedDevices[i];
                        detailedDevs[dev.deviceId] = dev.deviceName;
                    }
                    let devCatNamesById = {basicDevices: basicDevs, detailedDevices: detailedDevs};


                    if (devCategories.success) {
                        if (devCategories.caregiverId) {
                            LocalStorageAPI.updateCaregiverId(devCategories.caregiverId);
                        }
                        (handler)(devCategories, devCatNamesById, responseCode);
                    } else {
                        console.log("getDeviceCategories: error getting unusual events data.");
                        (handler)(undefined, undefined, -1);
                    }
                }
                catch (e) {
                    console.log("getDeviceCategories: Error parsing unusual events data JSON. Err = " + e.message);
                    (handler)(undefined, undefined, -2);
                }
            })
            .catch(function (error) {
                console.log("getDeviceCategories: " + error);
                (handler)(undefined, undefined, error.response.status);
            });

        return true;
    }


    function getChargeLevels(patientId, handler) {
        let url = URLS.getSensorBatteryLevels;
        let pData = dataFromLocalStorage(undefined, patientId, undefined);
        patientId = pData.patientId;
        let caregiverId = pData.caregiverId;

        axios.post(url, {caregiverId: caregiverId, patientId: patientId})
            .then(function (response) {
                let jsonData = response.data;
                let responseCode = response.status;
                let chargeLevels;

                try {
                    if (typeof jsonData === "string") {
                        chargeLevels = JSON.parse(jsonData);
                    } else {
                        chargeLevels = jsonData;
                    }

                    console.log("getChargeLevels: (jsondata) - " + jsonData);
                    if (chargeLevels.success) {
                        if (chargeLevels.caregiverId) {
                            LocalStorageAPI.updateCaregiverId(chargeLevels.caregiverId);
                        }
                        (handler)(chargeLevels, responseCode);
                    } else {
                        console.log("getChargeLevels: error getting medical device data.");
                        (handler)(undefined, -1);
                    }
                }
                catch (e) {
                    console.log("getChargeLevels: Error parsing medical data JSON. Err = " + e.message);
                    (handler)(undefined, -2);
                }
            })
            .catch(function (error) {
                console.log("getChargeLevels: " + error);
                (handler)(undefined, error.response.status);
            });

        return true;
    }

    function getHealthReport(caregiverId, patientId, startDate, endDate, handler) {
        let url = URLS.getHealthReport;
        let pData = dataFromLocalStorage(caregiverId, patientId, undefined);
        patientId = pData.patientId;
        caregiverId = pData.caregiverId;

        axios.post(url, {
            caregiverId,
            patientId,
            startDate,
            endDate,
            medAccess: true
        }).then(function (response) {
            let jsonData = response.data;
            let responseCode = response.status;
            let healthReport;

            try {
                if (typeof jsonData === "string") {
                    healthReport = JSON.parse(jsonData);
                } else {
                    healthReport = jsonData;
                }

                console.log("healthReport: (jsondata) - " + jsonData);
                if (!healthReport.error) {
                    if (healthReport.caregiverId) {
                        LocalStorageAPI.updateCaregiverId(healthReport.caregiverId);
                    }
                    (handler)(healthReport, responseCode);
                } else {
                    console.log("getHealthReport: error getting health report data.");
                    (handler)(undefined, -1);
                }
            } catch (e) {
                console.log("getHealthReport: Error parsing medical data JSON. Err = " + e.message);
                (handler)(undefined, -2);
            }
        })
            .catch(function (error) {
                console.log("getHealthReport: " + error);
                (handler)(undefined, error.response.status);
            });


        return true;
    }
    function isDisplayTimeoutPopped() {
        return showTimeoutPopup;
    }
    function setDisplayTimeoutPopped( popped ) {
        showTimeoutPopup = popped;
        return showTimeoutPopup;
    }

    function getLocationAlertArmed(patientId, handler) {
        let url = URLS.getLocationAlertArmed;
        let pData = dataFromLocalStorage(undefined, patientId, undefined);
        patientId = pData.patientId;
        let caregiverId = pData.caregiverId;

        axios.post(url, {caregiverId: caregiverId, patientId: patientId})
            .then(function (response) {
                let jsonData = response.data;
                let responseCode = response.status;
                let armedData;

                try {
                    if (typeof jsonData === "string") {
                        armedData = JSON.parse(jsonData);
                    } else {
                        armedData = jsonData;
                    }

                    // Convert from a string to a boolean
                    if (armedData.armed == "true") {
                        armedData.armed = true;
                    }
                    if (armedData.armed == "false") {
                        armedData.armed = false;
                    }

                    console.log("getLocationAlertArmed: (jsondata) - " + jsonData);
                    if (armedData.success) {
                        if (armedData.caregiverId) {
                            LocalStorageAPI.updateCaregiverId(armedData.caregiverId);
                        }
                        (handler)(armedData, responseCode);
                    } else {
                        console.log("getLocationAlertArmed: error getting data.");
                        (handler)(undefined, -1);
                    }
                }
                catch (e) {
                    console.log("getLocationAlertArmed: Error parsing data JSON. Err = " + e.message);
                    (handler)(undefined, -2);
                }
            })
            .catch(function (error) {
                console.log("getLocationAlertArmed: " + error);
                (handler)(undefined, error.response.status);
            });

        return true;

    }

    function setLocationAlertArmed(patientId, armed, callback) {
        let pData = dataFromLocalStorage(undefined, patientId, undefined);
        patientId = pData.patientId;
        let caregiverId = pData.caregiverId;

        let url = URLS.setLocationAlertArmed;
        
       // console.log('setLocationAlertArmed:  armed: ' + armed);

        axios.post(url, {caregiverId: caregiverId, patientId: patientId, flagValue: armed})
            .then(function (response) {
                let status = response.data;
                let statusCode = response.status;

                try {
                    if (status.success) {
                        if (status.caregiverId) {
                            LocalStorageAPI.updateCaregiverId(status.caregiverId);
                        }
                        callback.handleUpdateOptions(statusCode);
                    } else {
                        console.log("setLocationAlertArmed: error sending options JSON.");
                        callback.handleUpdateOptions(-1);
                    }
                }
                catch (e) {
                    console.log("setLocationAlertArmed: error parsing options JSON. Err = " + e.message);
                    callback.handleUpdateOptions(-2);
                }
            })
            .catch(function (error) {
                let statusCode = error.response.status;
                console.log("setLocationAlertArmed: " + error);
                callback.handleUpdateOptions(statusCode);
            });

        return true;

    }

    return {
        getPatientList: getPatientList,
        getPatient: getPatient,
        getPatientAsync: getPatientAsync,
        getPatientOptions: getPatientOptions,
        getDefaultOptions: getDefaultOptions,
        setPatientOptions: setPatientOptions,
        getFilteredPatientList: getFilteredPatientList,
        getPatientSummary: getPatientSummary,
        getPatientDetails: getPatientDetails,
        resetPatientInfo: resetPatientInfo,
        getHMapData: getHMapData,
        getLegendData: getLegendData,
        getUnusualEvents: getUnusualEvents,
        getRxDeviceData: getRxDeviceData,
        getDeviceEvents: getDeviceEvents,
        getDeviceCategories: getDeviceCategories,
        getChargeLevels: getChargeLevels,
        getHealthReport: getHealthReport,
        isDisplayTimeoutPopped: isDisplayTimeoutPopped,
        setDisplayTimeoutPopped: setDisplayTimeoutPopped,
        getLocationAlertArmed: getLocationAlertArmed,
        setLocationAlertArmed: setLocationAlertArmed,
        keepAlive: keepAlive,
    }
};

epyDataFactory = _epyDataFactory();

let PatientDataStorage = {
    getPatientList: function (caregiverId, callback) {
        return epyDataFactory.getPatientList(caregiverId, callback);
    },
    getFilteredPatientList: function (filter, attentionCheckedState) {
        return epyDataFactory.getFilteredPatientList(filter, attentionCheckedState);
    },
    getPatient: function (caregiverId, patientId) {
        return epyDataFactory.getPatient(caregiverId, patientId);
    },
    getPatientAsync: function (caregiverId, patientId, callback) {
        return epyDataFactory.getPatientAsync(caregiverId, patientId, callback);
    },
    getPatientOptions: function (caregiverId, patientId, callback) {
        return epyDataFactory.getPatientOptions(caregiverId, patientId, callback);
    },
    getDefaultOptions: function (level) {
        return epyDataFactory.getDefaultOptions(level);
    },
    setPatientOptions: function (caregiverId, patientId, patientOptions, callback) {
        return epyDataFactory.setPatientOptions(caregiverId, patientId, patientOptions, callback);
    },
    getPatientSummary: function getPatientSummary() {
        return epyDataFactory.getPatientSummary();
    },
    getPatientDetails: function (caregiverId, patientId, handler) {
        return epyDataFactory.getPatientDetails(caregiverId, patientId, handler);
    },
    resetPatientInfo: function resetPatientInfo() {
        return epyDataFactory.resetPatientInfo();
    },
    getSleepHMapData: function(patientId, startDate, daysOfData, handler) {
        return epyDataFactory.getHMapData(patientId, startDate, daysOfData, handler, 'sleeping');
    },
    getMealsHMapData: function(patientId, startDate, daysOfData, handler) {
        return epyDataFactory.getHMapData(patientId, startDate, daysOfData, handler, 'meals');
    },
    getActivityHMapData: function(patientId, startDate, daysOfData, handler) {
        return epyDataFactory.getHMapData(patientId, startDate, daysOfData, handler, 'activity');
    },
    getLegendData: function(caregiverId, patientId, hmType, handler) {
        return epyDataFactory.getLegendData(caregiverId, patientId, hmType, handler);
    },
    getUnusualEvents(caregiverId, patientId, startDate, daysOfData, handler) {
        return epyDataFactory.getUnusualEvents(caregiverId, patientId, startDate, daysOfData, handler);
    },
    getRxDeviceData(caregiverId, patientId, startDate, daysOfData, handler) {
        return epyDataFactory.getRxDeviceData(caregiverId, patientId, startDate, daysOfData, handler);
    },
    getDeviceEvents(patientId, isBasicEvents, deviceId, startDate, daysOfData, handler) {
        return epyDataFactory.getDeviceEvents(patientId, isBasicEvents, deviceId, startDate, daysOfData, handler);
    },
    getDeviceCategories(patientId, handler) {
        return epyDataFactory.getDeviceCategories(patientId, handler);
    },
    getChargeLevels(patientId, handler) {
        return epyDataFactory.getChargeLevels(patientId, handler);
    },
    getHealthReport(caregiverId, patientId, startDate, endDate, handler) {
        return epyDataFactory.getHealthReport(caregiverId, patientId, startDate, endDate, handler);
    },
    isDisplayTimeoutPopped() {
        return epyDataFactory.isDisplayTimeoutPopped();
    },
    setDisplayTimeoutPopped(popped) {
        return epyDataFactory.setDisplayTimeoutPopped(popped);
    },
    getLocationAlertArmed(patientId, handler) {
        return epyDataFactory.getLocationAlertArmed(patientId, handler);
    },
    setLocationAlertArmed(patientId, armed, handler) {
        return epyDataFactory.setLocationAlertArmed(patientId, armed, handler);
    },
    keepAlive() {
        epyDataFactory.keepAlive();
    }
};

export default PatientDataStorage;
