/*******
 * Copyright 2017-2021 - EmPowerYu, inc.
 *
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import MPYNavBar from './MPYNavBar';
import {HeaderFooterStrings} from './strings';

class MPYHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {loggedIn: props.loggedIn, userType: props.userType};
    }

    render() {
        return (
            <div>
                <div id="mpy-header"></div>
                <MPYNavBar/> 
            </div>
        )
    }
}

MPYHeader.propTypes = {
    loggedIn: PropTypes.bool, 
    path: PropTypes.string, 
    userType: PropTypes.any
}

class MPYFooter extends Component {
    render() {
        let copyrightText = <span>&copy;{HeaderFooterStrings.copyright}&trade;{HeaderFooterStrings.rights}</span>;
        // TODO(lee,rich): disable the copyright line for now.
        // maybe see if we can use a generic copyright statement such as "copyright by the authors" 
        // I may have seen that that suffices, but need to check.
        // for now, get lint to ignore the disused HeaderFooterStrings.
        copyrightText = " ";
        return (
                <div className="clearFix" id="mpy-footer">{copyrightText}</div>
        );
    }
}

export { MPYHeader, MPYFooter }
